<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <q-btn
          unelevated
          label="Tambah Libur"
          icon="add"
          color="primary"
          v-can="['ADMIN', 'AKADEMIK', 'PENGASUHAN']"
          @click="showDialogEdit(-1)"
        ></q-btn>
        <q-input outlined label="Cari Libur" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>tanggal</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filLibur" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.tanggal | moment("DD MMM YYYY") }}</td>
            <td class="q-gutter-sm">
              <q-btn
                flat
                dense
                icon="edit"
                color="secondary"
                v-can="['ADMIN', 'AKADEMIK', 'PENGASUHAN']"
                @click="showDialogEdit(val.id)"
              ></q-btn>
              <q-btn
                flat
                dense
                color="red"
                icon="delete_forever"
                v-can="['ADMIN', 'AKADEMIK', 'PENGASUHAN']"
                @click="(todel = val), (confirm = true)"
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions>
        <q-btn
          flat
          color="primary"
          label="back"
          @click="$router.go(-1)"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="confirm">
      <q-card>
        <q-card-section v-if="todel.id">
          Apa Anda Yakin Ingin Menghapus {{ todel.nama }} pada tanggal
          {{ todel.tanggal | moment("DD MMM YYYY") }}
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="Tidak"
            @click="todel = {}"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="Ya"
            @click="delete_libur"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from "moment";
import DialogTambahLibur from "@/components/DialogTambahLibur";
export default {
  data() {
    return {
      searchTerm: "",
      hari: [],
      confirm: false,
      todel: {},
    };
  },

  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getHariLibur();
    this.$q.loading.hide();
  },
  computed: {
    filLibur() {
      if (this.searchTerm == "") return this.hari;
      else {
        return this.hari.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  methods: {
    async delete_libur() {
      await this.$http.put(`/pengaturan/hari_libur/hapus/${this.todel.id}`);
      this.$q.notify({
        message: `Hari ${this.todel.nama} berhasil Dihapus`,
        color: "negative",
      });
      await this.getHariLibur();
    },
    showDialogEdit(id) {
      this.$q
        .dialog({
          component: DialogTambahLibur,
          parent: this,
          idHariLibur: id,
          id_tahun_ajaran: this.$route.params.id,
        })
        .onOk((resp) => {
          this.getHariLibur();
        });
    },
    async getHariLibur() {
      let resp = await this.$http.get(
        `/pengaturan/hari_libur/getlibur/${this.$route.params.id}`
      );
      this.hari = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
