<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          label="Nama Libur"
          color="primary"
          v-model="bundleLibur.nama"
        ></q-input>
        <q-input filled v-model="bundleLibur.tanggal" readonly>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="bundleLibur.tanggal"
                  mask="YYYY/MM/DD"
                  :options="dateOptionAwal"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-checkbox
          v-model="isRanged"
          label="Range"
          v-if="idHariLibur < 0"
        ></q-checkbox>
        <q-input filled v-model="bundleLibur.hingga" v-if="isRanged" readonly>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="bundleLibur.hingga"
                  :options="dateOptionAkhir"
                  mask="YYYY/MM/DD"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          v-if="idHariLibur < 0"
          :disable="isNameFilled() || (isRanged && bundleLibur.hingga == null)"
          color="primary"
          @click="createLibur"
          >Tambah</q-btn
        >
        <q-btn v-else color="primary" @click="editLibur">Edit</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import moment from "moment";
export default {
  props: ["idHariLibur", "id_tahun_ajaran"],
  data() {
    return {
      isRanged: false,
      batas: null,
      bundleLibur: {
        id_tahun_ajaran: null,
        nama: "",
        tanggal: null,
        hingga: null,
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    let resp = await this.$http.get(
      `/pengaturan/tahun_ajaran/getsingle/${this.$route.params.id}`
    );
    this.batas = resp.data;
    this.bundleLibur.tanggal = moment(this.batas.tanggal_mulai).format(
      "YYYY/MM/DD"
    );
    if (this.idHariLibur > 0) {
      await this.getDetail();
    }
    this.$q.loading.hide();
  },
  methods: {
    async getDetail() {
      let resp = await this.$http.get(
        `/pengaturan/hari_libur/getdetail/${this.idHariLibur}`,
        this.bundleLibur
      );
      this.bundleLibur = resp.data;
    },
    async createLibur() {
      this.bundleLibur.id_tahun_ajaran = this.id_tahun_ajaran;
      if (this.isRanged) {
        let days = [];
        for (
          var d = moment(this.bundleLibur.tanggal);
          d <= moment(this.bundleLibur.hingga);
          d = moment(d).add(1, "days")
        ) {
          days.push(moment(d).format("YYYY-MM-DD"));
        }
        this.bundleLibur.days = days;
      }
      await this.$http.post(
        `/pengaturan/hari_libur/add/${this.isRanged}`,
        this.bundleLibur
      );
      this.$q.notify({
        message: "Hari libur berhasil disimpan",
        color: "positive",
      });
      this.onOKClick();
    },
    async editLibur() {
      await this.$http.put(`/pengaturan/hari_libur/update`, this.bundleLibur);
      this.$q.notify({
        message: "Hari libur berhasil di update",
        color: "positive",
      });
      this.onOKClick();
    },
    dateOptionAwal(date) {
      return (
        date >= moment(this.batas.tanggal_mulai).format("YYYY/MM/DD") &&
        date <= moment(this.batas.tanggal_selesai).format("YYYY/MM/DD")
      );
    },
    dateOptionAkhir(date) {
      this.bundleLibur.hingga = moment(this.bundleLibur.tanggal)
        .add(1, "days")
        .format("YYYY/MM/DD");
      return (
        date > moment(this.bundleLibur.tanggal).format("YYYY/MM/DD") &&
        date <= moment(this.batas.tanggal_selesai).format("YYYY/MM/DD")
      );
    },

    isNameFilled() {
      if (this.bundleLibur.nama == "") return true;
      else return false;
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {};
      this.$emit("ok", data);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
